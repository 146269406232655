@import "bootstrap/dist/css/bootstrap.min.css";

@import "Custom.css";

body {
  background-color: var(--project-background);
  color: var(--text-color);
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.overflow-auto{
  overflow: auto !important;
}
.overflow-hidden{
  overflow: hidden !important;
}
.dropdown-toggle::after {
  display: none !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-image {
  animation: rotation 0.7s linear infinite;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  max-height: 90%;
  width: 85%;
  cursor:default;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-90w .modal-content {
  background-color: white;
  border-radius: 8px;
  max-height: 90%;
  width: 100%;
  cursor:default;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
div.fade.in.modal {
  display:flex !important;
  justify-content: center!important;
}

/* .agile-doc-modal */
.modal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 100%;
  margin: 50px auto;
  overflow-y: auto;
}

div.fade.modal.show{
  overflow: hidden;
}

.accordion-item {
  transition: all 0.4s ease; /* Add smooth animations for all acordeon properties */
}


