.wrapper {
    display: flex;
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
}
.labelLinkBlock{
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    margin: 0 8px 0 8px;
    padding-left: 8px;
}
.labelBlock {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    cursor: pointer;

    .fileName {
        padding-left: 8px;
    }

    .chooseFile {
        display: flex;
        align-items: center;
        width: 50%;
        height: 36px;
        text-align: center;
        background-color: #c0c0c0;
        border-radius: 5px 0 0 5px;
        padding-left: 8px;
    }

    .noFileChosen {
        width: 50%;
        height: 36px;
        border-radius: 0 5px 5px 0;
        padding: 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        line-height: 36px;
    }
}