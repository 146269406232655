:root {
  --project-background: #edf2f9;
  --background-block: #f9fafd;
  --text-color: #344050;
  --hover-grey: #bfbfbf;
  --timeline-color: #b8c2cc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.is-invalid {
  border-color: red;
}
.fw-600 {
  font-weight: 600;
}
.fs-10 {
  font-size: 10px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
/* .ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
.hover-grey:hover {
  cursor: pointer;
  color: var(--hover-grey);
}
.grab{
  cursor: grab;
}
.card-title{
  font-size: 1.2rem;
  font-weight: 500;
}

.btn-light{
  background-color: transparent!important;
}
.btn-light:hover{
  border: none!important;
}

