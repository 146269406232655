.reports-wrapper {
  padding: 20px;
}
.reports-innerWrapper {
  background-color: #fff;
  padding: 0px 24px 24px;
  border-radius: 5px;
}
.reportNameHeader{
  margin-left:12px;
}
.reportNameHeader,
.reportNameBody {
  min-width:250px;
  width: 650px;
  padding-left: 20px;
}
.reportOwnerHeader,
.ownerNameBody {
  /* text-align: center; */
  max-width: 200px;
  width: 250px
}
.reportDuedateHeader, .reportDuedateBody {
  width: 100px;
  text-align: center;
}
.reportPriorityHeader, .reportPriorityBody {
  width: 60px;
  text-align: center;
  padding:0px;
}
.report-item-wrapper {
  /* background-color: #eafaf1; */
  /* border-bottom: 1px solid lightgray; */
  cursor: pointer;
}
.dateReportPriorityBlock{
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .navItem:nth-child(2) {
    order: 1;
    min-width: 100%;
    justify-content: center;
  }
  .reportNameHeader,
  .reportOwnerHeader,
  .reportPriorityHeader,
  .reportDuedateHeader {
    display: none;
  }
}
@media (max-width: 1199.4px) {
  .reportNameBody {
    min-width: 250px;
    width: 400px;
  }
}
@media (max-width: 991.2px) {
  .reportNameBody {
    width: 100%;
  }
  .report-item{
    flex-wrap: wrap;
  }
  .dateReportPriorityBlock{
    margin-top: 8px;
  }
  .reportOwnerHeader,
  .reportPriorityHeader,
  .reportDuedateHeader {
    display: none;
  }
}

.backToFilters{
  cursor:pointer;
}