.fieldWrapper {
    display: flex;
    flex-direction:column;
    // align-items: flex-end;
    width: 100%;
}

.nameTypeBlock {
    display: flex;
    // align-items: flex-end;
    flex-grow:1;
    // margin-left:4px;
    // width: 50%;
}
.fieldType {
    flex-grow: 1;
    max-width: 100%; 
}
// .visibleEditorsBlock {
//     display: flex;
//     align-items: flex-end;
//     width: 50%;
// }

@media (max-width: 995px) {
    .fieldWrapper {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    // .nameTypeBlock {
    //     display: flex;
    //     align-items: flex-end;
    //     width: 100%;
    //     margin-right: 44px;
    // }

    .fieldName, .fieldType {
        flex: 1;
        max-width: 100%; 
    }
    .fieldType {
        flex-grow: 1;
        max-width: 100%; 
        margin-right: 0px;
    }
    .visibleEditorsBlock {
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-top: 8px;
        margin-left: 36px;
    }
}