.membersWrapper {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(33.33% - 20px), 1fr)
  ); 
  gap: 20px; 
}

.memberCard {
  background-color: #f9fafd;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .membersWrapper {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(50% - 20px), 1fr)
    ); 
  }
}

.memeberCardHeader {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}
.memberTasks {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 14px;
  margin-top: 8px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.memberTaskName {
  cursor: pointer;
  &:hover {
    color: var(--hover-grey);
  }
}

.taskBadge{
  margin-right: 4px;
  font-size:8px;
  &:last-child{
    margin-right: 0px;
  }
}
