.task-hr {
  margin-top: 5px;
  margin-bottom: 7px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.fc .fc-toolbar-title {
  font-size: 1.45em !important;
  margin: 0;
}

.fc .fc-button {
  padding: 0.2em 0.4em !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.7em !important;
}
textarea.agile-textarea.form-control {
  /* height: 100px; */
  font-size: 9pt !important;
}

@media screen and (max-width: 768px) {
  .hideShowBtn {
    margin-top: 8px;
  }
}

.docsToggle.dropdown-toggle.btn.btn-primary {
  font-size: 14px !important;
  color: #344050 !important;
  padding: 4px 8px!important;
  border-color: #d0e1f4 !important;
  background-color: #fff !important;
  /* width: 140px !important; */
  height: 30.6px !important;
  box-shadow:
    0 0 0 1px rgba(43, 45, 80, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
}

.docsToggle.dropdown-toggle.btn.btn-primary:hover {
  background-color: #dde1e7 !important;
  box-shadow:
    0 0 0 1px rgba(43, 45, 80, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}
div.docsDropdownMenu.dropdown-menu.show {
  min-width: 100px !important;
}
div.docsDropdownMenu.dropdown-menu.show.dropdown-menu-end {
  min-width: 100px !important;
}

.fileUploaderElement {
  width: 49.4%;
  &:nth-child(odd) {
    margin-right: 8px;
  }
}
@media screen and (max-width: 992px) {
  .fileUploaderElement {
    width: 100%;
    &:nth-child(odd) {
      margin-right: 0px;
    }
  }
}
