.addFilterForm{
    display: flex;
    flex:1;
    justify-content: space-between;
}
.emailSubjectBlock {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 991.5px) {
    .addFilterForm{
        display: flex;
        flex-wrap: wrap;
    }
    .emailSubjectBlock {
      width: 100%; 
    }
    .submitBtn{
      display: flex;
      justify-content: flex-end;
      width: 100%; 
      margin-top: 8px;
    }
  }
.addFilterBtn{
    height: 37.6px;
    min-width: 102px!important;
}
/* @media screen and (orientation: landscape) {
    .addFilterBtn{
      height: 37.6px;
      min-width: 100px!important;
  }
} */