/* -----------Participants adaptive---------------------------------------------------------------------------------------------------------------- */

.formWrapper {
  display: flex;
  width: 100% !important;
}

.participants {
  /* width: 370px; */
  width: fit-content;
}

.nameBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.btnParticipants,
.collapseBlock,
.timelineHorizontal {
  display: none;
}

.mainBlock {
  width: 70%;
}

.nameInput {
  width: 100%;
}

@media (max-width: 1255px) {
  .mainBlock {
    width: 100%;
  }

  .nameInput {
    flex: 1;
  }

  .btnParticipants {
    display: flex;
    cursor: pointer;
  }

  .collapseBlock {
    display: flex;
    width: 100%;
  }
  .timelineHorizontal {
    display: block;
  }
  .participants {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .nameBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .btnParticipants {
    width: 100%;
  }
  .collapseBtn{
    margin-top: 8px;
    width: 100%;
  }
}
