.participantsWrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.btnPlus {
  margin-top: 32px;
}
.participantForm {
  margin-top: 8px;
  margin-bottom: 16px;
}

.lastItem {
  margin-top: 8px;
}

.deleteButton {
  display: none;
}
.typeStatusBlock {
    display: flex;
    width: 100%;
  }
@media (max-width: 1255px) {
  .participantForm,
  .lastItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .email {
    width: 100%;
    margin-left: 8px;
  }

  .btnPlus {
    margin-top: 8px;
  }

  .fullScreenDeleteBtn {
    display: none !important;
  }

  .deleteButton {
    display: flex;
  }
  .form-group {
    flex-basis: 48%;
  }
  .typeStatusBlock {
    display: flex;
    // margin-bottom: 8px;
    // margin-left: 0px;
    width: 100%;
  }
  .type,
  .status {
    width: 100%;
    margin-left: 8px;
  }
}

// ===============================mobile version==========================================

.typeStatusBlock {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.email {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-left: 8px;
}

.type,
.status {
  width: 50%;
  margin-left: 8px;
}
@media (max-width: 768px) {
  .participantForm,
  .lastItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .email {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
  }
  .typeStatusBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-left: 0px;
    width: 100%;
  }
  .type {
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0px;
  }
  .status {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0px;
  }
}
