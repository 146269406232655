.lastItem {
  cursor: default;

  &:hover {
    background-color: #fff !important;
  }
}
// -------for ContextAwareToggle.js----------------------
.activeName {
  color: var(--text-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
}

.passiveNameHover {
  @extend .activeName; 
  color: lightgrey;

  &:hover {
    color: #9babc4;
  }
}
@media screen and (max-width: 768px) {
  .activeName {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    cursor: pointer;
  }
  .passiveNameHover {
    @extend .activeName; 
    color: lightgrey;
  
    &:hover {
      color: #9babc4;
    }
  }
}

// --------------SearchInput-------------------------------------------------------------
.searchDropdown{
  position: absolute;
  background-color: white; 
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 249px;
  max-height: 400px;
  overflow-y: auto;
}

// ---------------DocumentLinks.js-----------------------------------------------------------
.docsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
}
.linkBtn{
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
}
@media (max-width: 1255px) {
  .docsGrid {
    grid-template-columns: repeat(4, 1fr);
  }
  .linkBtn{
    width: 240px;
  }
}

@media (max-width: 1200px) {
  .docsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 910px) {
  .docsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .docsGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .linkBtn{
    width: 100%;
  }
}
