nav {
    /* height: 80px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--project-background);
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    padding:8px;
  }
  
  .nav-logo {
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .nav-menu {
    display: none;
    cursor: pointer;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  
  .nav-links ul {
    display: flex;
    list-style: none;
    /* align-items: center; */
  }
  
  .nav-links li {
    list-style: none;
    color: #344050;
  }
  
  .nav-links a {
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    color: #344050;
  }
  .nav-close{
    color:#344050;
    display: block;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .nav-menu {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 75%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .nav-links {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: var(--project-background);
      display: flex;
      flex-direction: column;
      /* text-align: center; */
      transform: translateY(-100%);
      transition: transform 0.5s ease-in;
      z-index:1000;
    }
  
    .nav-links ul {
      flex-direction: column;
      margin: 0;
      padding: 0;
    }
  
    .nav-links li {
      width: 100%;
      list-style: none;
    }
  
    .nav-links.active {
      transform: translateY(0);
      display: flex;

    }

    .mobile-dropdown {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 99999;
        border-radius: 16px 16px 0px 0px;
        animation-duration: 0.3s;
        max-height: 400px;
        overflow: auto;
        padding-bottom: 16px;
        padding-left: 16px;
      }
      
      .animate__animated.animate__slideInUp {
        animation-name: slideInUp;
      }
      
      .animate__animated.animate__slideOutDown {
        animation-name: slideOutDown;
      }
      
      @keyframes slideInUp {
        from {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      
      @keyframes slideOutDown {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(0, 100%, 0);
          visibility: hidden;
        }
      }
  }
