/* .filtersRow {
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  grid-column-gap: 10px;
  align-items: center;
  cursor: pointer;
} */
.filtersRow, .filtersHeaderRow {
  display: flex;
  align-items: center;
}

.filterWorkspace,
.filterProject {
  flex: 1; /* займають рівні частини вільного місця */
  min-width: 0; /* щоб вони могли стискатися при необхідності */
  padding: 0 10px; /* додаємо відступи всередині елементів */
  box-sizing: border-box; /* щоб відступи не впливали на ширину елементів */
}
.filtersRow:hover {
  color: lightgray;
  cursor: pointer;
}
.filterName {
  width: 300px;
}

.editIcon {
  color: #5e6e82;
}
.trashIcon {
  color: #dc3545;
  width: 12px;
}
.filters-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.action-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 50px;
}
