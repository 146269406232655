.navItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.nav-search {
  position: relative;
  z-index: 999;
}
/* @media screen and (max-width: 1367px) { */
  @media screen and (max-width: 1416px) {
  .navItem:nth-child(2) {
    flex-basis: 100%;
    order: 3;
    justify-content: center;
  }
}

.bottom-left-dropdown .dropdown-menu {
  position: absolute !important;
  right: 0 !important;
  top: 100% !important;
}

/* report.css */
.report-filters-content {
  min-height: 50px;
}

.form-label.lowercase {
  text-transform: lowercase;
}

.w-20 {
  width: 20%;
}
.prioritySwitch{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 49%;
}
.formElement {
  width: 49%;
}

.formSwitch {
  display: flex;
  justify-content: space-between;
}
.switchGroup {
  display: flex;
  justify-content: space-between;
  width: 49%;
}

@media screen and (max-width: 991.5px) {
  .formElement {
    width: 100%;
  }
  .prioritySwitch{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .formSwitch {
    display: flex;
    flex-direction: column;
  }
  .switchGroup:nth-child(odd) {
    margin-bottom: 8px;
  }
  .switchGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
