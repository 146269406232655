.bg-gray {
  background-color: gray;
}

a {
  color: #5e6e82;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
}

@media (min-width: 1540px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1480px;
  }
}

#navbarScroll.navbar-collapse {
  flex-grow: unset;
}

.card {
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);

  background-clip: border-box;
  border: 0 solid #edf2f9;
}

.card .accordion-collapse {
  background-color: #fff;
  border-radius: 0.25rem;
}

.card-header {
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
  padding: 16px;
}

.btn-outline-primary {
  color: #273748;
  border-color: #273748;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #273748;
  border-color: #273748;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #748194;
}

.inactive .svg-inline--fa {
  color: lightgray;
}

.card-header.inactive {
  background-color: #e9e9eb !important;
  cursor: pointer;
}

.item-menu-btn {
  padding: 3px 12px;
  /* line-height: 1.5; */
  /* border-radius: 0.2rem; */
  margin-right: 0.5rem;
  border: transparent !important;
}

.dropdown .item-menu-btn:focus {
  box-shadow: none !important;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.dropdown .item-menu-btn:focus .svg-inline--fa {
  color: #0b1727;
}

/* .card-header:hover .dropdown .item-menu-btn {
  -webkit-box-shadow: 0 0 0 1px rgb(43 45 80 / 10%),
    0 2px 5px 0 rgb(43 45 80 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 7%),
    0 1px 2px 0 rgb(0 0 0 / 8%);
  box-shadow: 0 0 0 1px rgb(43 45 80 / 10%), 0 2px 5px 0 rgb(43 45 80 / 8%),
    0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%);
} */

.dropdown-menu {
  font-size: 0.83333rem;
  overflow: hidden;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  border: 1px solid #d8e2ef !important;
}

.dropdown-item {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #5e6e82;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--text-color);
  text-decoration: none;
  background-color: #edf2f9;
}
.task-row:hover .svg-inline--fa {
  color: #0b1727;
  background-color: #edf2f9;
}

.account-icon .btn {
  background: none;
  border: none;
}

.fc .fc-day-future .fc-daygrid-event {
  background-color:var(--timeline-color) !important;
  border-color: var(--timeline-color)!important;
}

.fc .fc-day-future .fc-event-main {
  border-color: var(--timeline-color)!important;
}

.fc .fc-day-today .fc-daygrid-event {
  background-color: #e6effc !important;
  border-color: #e6effc !important;
}
.fc .fc-day-today .fc-event-main {
  color: #1862c6 !important;
}
.fc .fc-day-today .fc-daygrid-day-frame {
  background-color: #e9e9e9;
}

.fc .fc-day-past .fc-daygrid-event {
  background-color: var(--timeline-color) !important;
  border-color: var(--timeline-color)!important;
}
.fc .fc-day-past .fc-event-main {
  color: #fff !important;
}

.fc-daygrid-day-top {
  justify-content: center;
}

.fc-daygrid-day-top .fc-daygrid-day-number {
  width: 1.875rem;
  height: 1.875rem;
  background-color: var(--background-block);
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
  line-height: 1.875rem;
  padding: 0 !important;
  margin-top: 5px;
  font-size: 0.83333rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fc-daygrid-day-top:hover .fc-daygrid-day-number {
  background-color: #edf2f9;
}

.fc .fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  background-color: #1862c6 !important;
  color: #fff;
}


.fc .fc-event-title-container{
  background-color: var(--timeline-color);
  /* margin-top:2px!important; */
  padding-left: 5px;
}
.top-nav-icon {
  padding: 0;
}

.top-nav-icon svg {
  color: rgba(0, 0, 0, 0.55);
}

.top-nav-icon:hover svg {
  color: rgba(0, 0, 0, 0.9);
}

.top-nav-icon:focus svg {
  color: #273748;
}

.notification-indicator {
  position: absolute;
  background-color: #273748;
  right: 4px;
  top: 1px;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 2px solid #edf2f9;
}

.notification-indicator.envelop {
  right: -5px;
  top: -5px;
}

.top-nav-icon:focus {
  box-shadow: none;
}

.vertical-timeline::before {
  background-color: #5e6e82 !important;
}

div.task-row:hover {
  background-color: #d3d3d370 !important;
}

.form-label {
  margin-bottom: 0;
  padding:0;
  font-size: 16px;
  text-transform: uppercase;
}

.fc {
  width: 100%;
  max-width: none;
}

.fc-daygrid-day {
  min-height: 60px;
}
.fc .fc-col-header-cell-cushion {
  text-decoration: none;
  font-size: 0.85rem !important;
  font-weight: 600;
}

.fc .fc-button-primary {
  background-color: transparent!important;
  border:none!important;
}
span.fc-icon {
  color: #273748 !important;
  transition: transform 0.2s;
}

span.fc-icon:hover {
  transform: scale(1.5);
}
/* .fc .fc-button-primary:hover {
  background-color: #355578 !important;
  border-color: #355578 !important;
} */

.card-body .grid .today rect {
  fill: #d3d3d370;
}

.message-link {
  cursor: pointer;
  font-weight: 600;
  color: #273748;
  text-decoration: none;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-header .btn-group .btn {
  font-size: 13px;
}
select#readOnlyPlaceholderId,
select#disabledId,
select:invalid,
select option[value=""],
select:disabled,
::placeholder {
  color: lightgray !important;
}
select#selectId,
select#readOnlyId {
  color: var(--bs-body-color) !important;
}
/* select#readOnlyPlaceholderId {
   color: lightgray !important;
} */
select,
select option {
  color: #212529 !important;
  cursor: pointer !important;
}
.text-primary,
.nav-link {
  color: #273748 !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #273748;
  border-color: #273748;
}
.btn-primary:active {
  color: #fff!important;
  background-color: #355578!important;
  border-color: #355578!important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #355578;
  border-color: #355578;
}

.action-btn.btn-primary,
.action-btn.btn-primary:focus,
.action-btn.btn-primary:disabled,
.action-btn.btn-primary.disabled {
  background-color:#fff;
  border-color: #d0e1f4!important;
  color:#344050;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)!important;
}

.action-btn.btn-primary:active {
  color:#344050!important;
  background-color: #DDE1E7!important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)!important;
}
.action-btn.btn-primary:hover {
  color:#344050;
  border: 1px solid transparent;
  background-color:#e9e9eb;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)!important;
}

.active-btn.btn-primary {
  color: #344050!important;
  background-color: #d0e1f4!important;
  border:1px solid #d0e1f4!important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)!important;
}

.active-btn.btn-primary:hover {
  color: #344050!important;
  background-color: #e9e9eb!important;
  border: none;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)!important;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.message {
  font-size: 16px;
  color: #344050;
  font-weight: 500;
  text-align: center;
}
/* ------------customized scroll---------------------------------------------------------------------- */
* {
  scrollbar-color: #ccc #ccc;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

* {
  scrollbar-width: thin;
}

.modal-header .btn-close {
  font-size: 12px !important;
}
.badge.bg-primary {
  background-color: #c0c0c0 !important;
}

/* react-datepicker */
.react-datepicker__header {
  background-color: white !important;
  padding: 8px;
}

div.react-datepicker__day-name {
  color: #5e6e82!important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 12.8px;
  width: 35px!important;
  height: 28px!important;
  margin: 3px!important;
}
.react-datepicker__day{
  color:#9da9bb!important;
  width: 35px!important;
  height: 28px!important;
  margin: 3px!important;
}
.react-datepicker__day--today{
  color:#9da9bb!important;
  background-color:#edf2f9!important;
  width: 35px!important;
  height: 28px!important;
  margin: 3px!important;
}
.react-datepicker__day--selected{
color: #fff!important;
background-color: #2c7be5!important;
}
.react-datepicker__navigation-icon {
  color: #ccc !important;
  margin-top: 15px;
}
 /* Loading styles */
  .animated-background {
    border-radius: 25px;
    background: #e3e3e3 !important;
    height: 30px;
  }

/* --------Draft-react editor ----------------------------------------------------------------------- */
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px!important;
  line-height: 1.5;
}
.wrapper-disabled {
  border: 1px solid transparent;
  border-radius: 6px!important;
}
.editor-wrapper {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px!important;
}
.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px!important;
  cursor: text;
  overflow-y: hidden!important;
  overflow-x: auto;
  z-index:1;
}
div.rdw-image-modal {
  z-index: 1000!important;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.zoom-in img {
  cursor: zoom-in;
  width: 280px;
  height: 180px;
}
div.rdw-link-modal{
  height:245px!important;
  right: 100%;
}
.editor-html a{
  word-break: break-all;
}
@media (max-width: 1286px) {
  .zoom-in img {
    cursor: zoom-in;
    width: 250px;
    height: 150px;
  }
}

/* ---------------------Gantt ---------------------------------*/
._WuQ0f {
  padding-left: 10px;
  font-weight: bold;
}
._nI1Xw div{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
._nI1Xw div:hover{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--hover-grey);
}
/* ._3lLk3 {
  min-width: 300px !important;
} */
/* horizontalScrollBar */
._2k9Ys{
  width: 500px!important;
  margin-left: 300px!important;
}
/* ._35nLX{
  display: flex;
  color: green!important;

} */
._2eZzQ{
  display: none !important;
}


